<template>
  <div class="card">
    <div class="card-header border-0 pt-6">
      <div class="card-title">
        <div class="d-flex align-items-center position-relative my-1">
          <input
            type="text"
            v-model="searchParams.search"
            v-on:keyup.enter="handleSearch"
            class="form-control form-control-solid w-250px me-2"
            placeholder="Search Role Details"
          />
        </div>
        <el-tooltip class="box-item" effect="dark" :content="generalConstants.SEARCH" placement="top">
          <button type="button" @click.prevent="handleSearch" class="btn btn-icon btn-light btn-sm me-2"><i class="bi bi-search"></i></button>
        </el-tooltip>
        <el-tooltip class="box-item" effect="dark" :content="generalConstants.RESET" placement="top">
          <button type="button" @click.prevent="resetSearch" class="btn btn-icon btn-light btn-sm"><i class="bi bi-arrow-counterclockwise"></i></button>
        </el-tooltip>
      </div>
      <div class="card-toolbar">
        <button type="button" class="btn btn-primary" v-if="permission.isAddAllowed" @click="$router.push({ name: 'add-role' })"><i class="bi bi-plus-circle-fill fs-4"></i>
          {{ generalConstants.ADD }}
        </button>
      </div>
    </div>
    <div class="card-body pt-0">
      <Datatable
        :table-data="tableData"
        :table-header="tableHeader"
        :rows-per-page="searchParams.recordsPerPage"
        :empty-table-text="noDataFound"
        :loading="loading"
        :enable-items-per-page-dropdown="true"
        ref="updateProps"
      >
        <template v-slot:cell-name="{ row: data }">
          {{ data.name }}
        </template>
        <template v-slot:cell-permissions="{ row: data }">
          <span v-for="permission in data.filterd_permission" :key="permission">
              <span class="badge badge-light-primary me-2 mb-1"> {{ permission }} </span>
            </span>
        </template>
        <template v-slot:cell-actions="{ row: data }">
          <div v-if="permission.isEditAllowed || permission.isDeleteAllowed">
            <el-tooltip class="box-item" effect="dark" v-if="permission.isEditAllowed" :content="generalConstants.EDIT" placement="top">
              <button type="button" @click.prevent="$router.push({ name: 'edit-role', params: { id: data.id } })" class="btn btn-icon btn-light btn-sm me-2">
                <i class="bi bi-pencil-square"></i>
              </button>
            </el-tooltip>
            <el-tooltip class="box-item" effect="dark"  v-if="permission.isDeleteAllowed" :content="generalConstants.DELETE" placement="top">
              <button type="button" @click.prevent="remove(data.id, tableData.findIndex((i) => i.id === data.id))" class="btn btn-icon btn-light btn-sm">
                <i class="bi bi-trash text-danger"></i>
              </button>
            </el-tooltip>
          </div>
        </template>
      </Datatable>
    </div>
  </div>
</template>
<script>
import { ref, onMounted, reactive } from "vue";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import ApiService from "@/core/services/ApiService";
import Datatable from "@/components/kt-datatable/KTDatatable.vue";
import { notificationFire } from "@/composable/notification.js";
import { ElMessageBox } from 'element-plus';
import { formatText } from "@/core/helpers/common";
import globalConstant from "@/core/data/globalConstant.js";
import { useRoute } from "vue-router";
import { loggedInUserRoles, loggedInUserPermissions } from "@/composable/get-roles.js";
import { Actions } from "@/store/enums/RememberSearchEnums";
import { useStore } from "vuex";
import { roleList } from "@/composable/roles";

export default {
  name: "roles",
   components: {
    Datatable,
  },
  setup() {
    const route = useRoute();
    const total = ref(0);
    const tableData = ref([]);
    const initCustomers = ref([]);
    const loading = ref(false);
    const updateProps = ref(null);
    const generalConstants = globalConstant.general;
    const noDataFound = ref(generalConstants.LOADING);
    const store = useStore();
    const moduleName = ref((route.meta.module) ? route.meta.module : '');
    const allRoles = roleList();
    const searchParams = reactive({
      search: store.getters.getSearch(moduleName.value),
      recordsPerPage: store.getters.getPerPage(moduleName.value),
      pageNub: store.getters.getPageNumber(moduleName.value)
    });
    const permission = reactive({
      isAddAllowed: false,
      isEditAllowed: false,
      isDeleteAllowed: false,
    })
    const tableHeader = ref([
      {
        name: generalConstants.SRNO,
        key: "index",
        sortable: false,
        width: "w-10px",
      },
      {
        name: "Name",
        key: "name",
        sortable: true,
        width: "w-100px",
      },
      {
        name: "Permissions",
        key: "permissions",
        width: "w-200px",
        sortable: false,
      },
      {
        name: generalConstants.ACTIONS,
        key: "actions",
        width: "w-200px",
        sortable: false,
      },
    ]);

    //Get all roles
    const getRoles = async () => {
      loading.value = true;
      await ApiService.query("roles", { params: searchParams })
        .then(({ data }) => {
          if (data.data && data.data.length) {
            tableData.value.splice(0, tableData.value.length, ...data.data);
            tableData.value.forEach((value,index) => {
              tableData.value[index]['filterd_permission'] = [];
              value.permissions.forEach((permission) => {
                allRoles.forEach((val) => {
                  if(val.permissions.includes(permission.name)){
                    if(!tableData.value[index]['filterd_permission'].includes(val.module)){
                      tableData.value[index]['filterd_permission'].push(val.module);
                    }
                  }
                });
              });
            });
            initCustomers.value.splice(0, tableData.value.length, ...data.data);
            total.value = data.data.length;
          } else {
            updateProps.value.updateProps(total.value, searchParams.recordsPerPage, searchParams.pageNub, generalConstants.NO_DATA_FOUND);
          }
          loading.value = false;
          setCurrentPageBreadcrumbs(formatText(route.name) + " (" + total.value + ")", [{ name: formatText(route.name), active: true }]);
          handleSearch();
        })
        .catch((error) => {
          let response = error.response;
          if (response) {
            let status = response.status;
            let message = response.data.message;
            notificationFire(message, "error");
          }
          tableData.value = [];
          loading.value = false;
        });
    };

    //Handle search event
    const handleSearch = () => {
      tableData.value.splice(0, tableData.value.length, ...initCustomers.value);
      if (searchParams.search !== "") {
        let results = [];
        for (let j = 0; j < tableData.value.length; j++) {
          if (searchingFunc(tableData.value[j], searchParams.search)) {
            results.push(tableData.value[j]);
          }
        }
        tableData.value.splice(0, tableData.value.length, ...results);
        if (results.length == 0) {
          updateProps.value.updateProps(total.value, searchParams.recordsPerPage, searchParams.pageNub, generalConstants.NO_DATA_FOUND);
        }
      }
      store.dispatch(Actions.SEARCH, {'module':moduleName.value,'value':searchParams.search});
      total.value = tableData.value.length;
      setCurrentPageBreadcrumbs(formatText(route.name) + " (" + total.value + ")", [{ name: formatText(route.name), active: true }]);
    }

    const searchingFunc = (obj, value) => {
      for (let key in obj) {
        if (!Number.isInteger(obj[key]) && !(typeof obj[key] === "object")) {
          if (obj[key].toUpperCase().indexOf(value.toUpperCase()) != -1) {
            return true;
          }
        }
      }
      return false;
    };

    //Reset search value
    const resetSearch = () => {
      if(searchParams.search.length > 0){
        searchParams.search = '';
        handleSearch();
      }
    }

    //Remove role data from listing page
    const remove = async (roleID, roleIndex) => {
      ElMessageBox.confirm(
        'Do you really want to delete this record?',
        'Warning',
        {
          confirmButtonText: 'OK',
          cancelButtonText: 'Cancel',
          type: 'warning',
        }
      )
      .then(() => {  
        ApiService.delete("roles/" + roleID)
        .then((response) => {
          let status = response.status;
          let message = response.data.message;
          if (status == 200) {
            tableData.value.splice(roleIndex, 1);
            notificationFire(message, "success");
            total.value = total.value > 0  ? total.value - 1 : 0;
            setCurrentPageBreadcrumbs(formatText(route.name) + " (" + total.value + ")", [{ name: formatText(route.name), active: true }]);
            if(tableData.value.length == 0){
              updateProps.value.updateProps(total.value, searchParams.recordsPerPage, searchParams.pageNub, generalConstants.NO_DATA_FOUND);
            }
          }
        })
        .catch((error) => {
          let message = error.message;
          notificationFire(message, "error");
        });
      })
    };

    //Set breadcrumbs and get the Blogs
    onMounted(async () => {
      setCurrentPageBreadcrumbs(formatText(route.name), [{ name: formatText(route.name), active: true }]);
      let role = loggedInUserRoles();
      let per = loggedInUserPermissions();
      if (per.includes("createRole") || role == "Super Admin")
        permission.isAddAllowed = true;
      if (per.includes("updateRole") || role == "Super Admin")
        permission.isEditAllowed = true;
      if (per.includes("deleteRole") || role == "Super Admin")
        permission.isDeleteAllowed = true;
      await getRoles();
      setCurrentPageBreadcrumbs(formatText(route.name) + " (" + total.value + ")", [{ name: formatText(route.name), active: true }]);
    });

    return {
      tableData,
      getRoles,
      total,
      handleSearch,
      loading,
      remove,
      resetSearch,
      searchParams,
      tableHeader,
      noDataFound,
      updateProps,
      generalConstants,
      permission,
      allRoles,
    };
  },
};
</script>
